<template>
  <div class="collectionAccountManagement">
    <en-table-layout ref="enTablateLayout" :tableData="tableData.data" :span-method="orderSpanMethod">
      <template slot="toolbar">
        <el-form-item label="更新时间" class="col-auto">
          <el-date-picker v-model="order_time_range" type="daterange" style="width: 250px" range-separator="-"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="handleDateChange" />
        </el-form-item>
        <el-form-item>
          <el-input style="width: 290px;" size="small" placeholder="请输入关键字进行搜索" v-model.trim="params.keyWord" clearable>
            <el-select v-model="key_word" slot="prepend" style="width: 100px;">
              <el-option label="公司名称" value="company_name"></el-option>
              <el-option label="开户银行" value="bank_name"></el-option>
              <el-option label="银行账号" value="bank_account"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item class="col-auto">
          <el-button native-type="submit" @click.stop.prevent="searchEvent" size="small" type="primary">搜索</el-button>
          <el-button @click="handleGoodsAudit('')" size="small" type="primary">添加账户</el-button>
        </el-form-item>
      </template>
      <template slot="table-columns">
        <el-table-column label="公司名称" prop="company_name" show-overflow-tooltip fixed="left" />
        <el-table-column label="开户银行" show-overflow-tooltip prop="bank_name" fixed="left" />
        <el-table-column label="银行账号" show-overflow-tooltip prop="bank_account" fixed="left" />
        <el-table-column v-if="drop_shipping_mall_open" label="设为默认" show-overflow-tooltip prop="enterprise_defalut"
          fixed="left">
          <template slot="header">
            <el-tooltip style="max-width:200px;" effect="dark" placement="top">
              <label class="cursor-pointer">设为默认<i class="el-icon-warning-outline"></i></label>
              <div slot="content">
                开启后，会将该开户银行及账号显示到该企业下【一件代发商城】中的充值弹窗页面。
              </div>
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <el-switch @change="handleLogisticsSwitch(scope.row)" :active-value="1" :inactive-value="0"
              v-model="scope.row.enterprise_defalut">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" show-overflow-tooltip label="创建时间" width="160">
          <template slot-scope="scope">{{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <el-table-column prop="create_time" show-overflow-tooltip label="更新时间" width="160">
          <template slot-scope="scope">{{ scope.row.update_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}</template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="250" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="openDetail(scope.row)">进项明细
            </el-button>
            <el-button size="mini" type="primary" @click="handleGoodsAudit(scope.row)">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>
    <!--编辑 dialog-->
    <x-dialog :proxy="examinationDialog" class="examinationDialog">
      <div style="max-height: 500px;overflow-y:auto" class="bank-form">
        <el-form style="padding:20px" :model="goodsAuditForm" :rules="goodsAuditRules" ref="goodsAuditForm"
          label-width="100px">
          <el-form-item label="公司名称" prop="company_name">
            <el-input type="text" maxlength="50" v-model.trim="goodsAuditForm.company_name"
              placeholder="请填写公司名称"></el-input>
          </el-form-item>
          <div class="bank-list" v-for="(item, index) in goodsAuditForm.enterprise_collecting_account_detail_dtolist"
            :key="index">
            <el-form-item label="开户银行">
              <el-input type="text" maxlength="50" v-model.trim="item.bank_name" placeholder="请填写开户银行名称"></el-input>
            </el-form-item>
            <el-form-item label="银行账号" :prop="`enterprise_collecting_account_detail_dtolist[${index}].bank_account`"
              :rules="goodsAuditRules.bank_account">
              <el-input type="text" maxlength="50" v-model.trim="item.bank_account" placeholder="请填写银行账号"></el-input>
            </el-form-item>
            <span class="el-icon-error delBank"
              v-if="goodsAuditForm.enterprise_collecting_account_detail_dtolist.length > 1"
              @click="delBank(index)"></span>
          </div>
        </el-form>
        <el-button style="margin:0 10px 30px 16px;"
          :disabled="goodsAuditForm.enterprise_collecting_account_detail_dtolist.length == 3" size="small"
          type="primary" @click="addBank">新增开户银行</el-button>
        <span>提示：开户银行及账号最多可添加3个。</span>
      </div>
    </x-dialog>
  </div>
</template>
<script>
import EnTableLayout from '@/../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import * as API_settingCustomer from '@/api/settingCustomer';
import { getShopExtAuth } from "@/api/order";
export default {
  name: 'collectionAccountManagement',
  components: { EnTableLayout, XDialog, },
  data () {
    return {
      key_word: 'company_name',
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        keyWord: "",
      },
      isCompanyName: '',//是否重复
      // 列表数据
      tableData: {
        data: []
      },
      order_time_range: [],
      examinationDialog: $xDialog.create({
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.submitGoodsAuditForm
      }),
      goodsAuditForm: {
        company_name: '',
        enterprise_collecting_account_detail_dtolist: [//开户银行
          {
            bank_name: '',
            bank_account: ''
          }
        ]
      },
      goodsAuditRules: {
        company_name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
          {
            validator: async (rule, value, callback) => {
              if (this.goodsAuditForm.id && this.isCompanyName != value) {//编辑
                const res = await API_settingCustomer.getModelByCompanyName(value)
                if (res) callback(new Error('公司名称已存在'))
                else callback()

              } else if (!this.goodsAuditForm.id) {//添加
                const res = await API_settingCustomer.getModelByCompanyName(value)
                if (res) callback(new Error('公司名称已存在'))
                else callback()
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        bank_name: [
          { required: true, message: '请输入开户银行', trigger: 'blur' },
          {
            required: true, validator: async (rule, value, callback) => {
              const bankNameList = this.goodsAuditForm.enterprise_collecting_account_detail_dtolist.filter(item => item.bank_name == value)
              if (bankNameList.length > 1) {//编辑
                callback(new Error('“开户银行及账号”存在重复'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        bank_account: [
          { required: true, message: '请输入银行账号', trigger: 'blur' },
          {
            required: true, validator: async (rule, value, callback) => {
              const bankAccountList = this.goodsAuditForm.enterprise_collecting_account_detail_dtolist.filter(item => item.bank_account == value)
              if (bankAccountList.length > 1) {//编辑
                callback(new Error('“开户银行及账号”存在重复'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
      },
      drop_shipping_mall_open: true,//大平台是否开启一件代发商城开关
    }
  },
  activated () {
    this.GET_GoodsAuditList();
    this.$refs.enTablateLayout.$refs.table?.doLayout();
  },
  async mounted () {
    const resStatus = await getShopExtAuth().then();
    this.drop_shipping_mall_open = resStatus.drop_shipping_mall_open === "OPEN";
    this.GET_GoodsAuditList();
  },
  methods: {
    /** 收款账户开启 /关闭 */
    async handleLogisticsSwitch (row) {
      console.log(row.enterprise_defalut);
      await API_settingCustomer.setDefaultInEnterprise(row.child_id, row.enterprise_defalut)
      this.GET_GoodsAuditList();
    },
    orderSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex >= 1 && columnIndex <= 3) {
        return {
          rowspan: 1,
          colspan: 1
        };
      } else {
        return row.span;
      }
    },
    /**合并单元格 */
    mergeSkuList (data) {
      return data.reduce((results, item) => {
        let sku_list = item["enterprise_collecting_account_detail_dtolist"];

        if (!sku_list) return;
        results.push(
          ...sku_list.map((row, index) => {
            let span = {
              rowspan: 0,
              colspan: 0,
            };

            if (index === 0) {
              span = {
                rowspan: sku_list.length,
                colspan: 1,
              };
            }
            return {
              span,
              id: item.id || "",
              company_name: item.company_name || "",
              bank_account: row.bank_account,
              bank_name: row.bank_name,
              create_time: item.create_time || '',
              update_time: item.update_time || '',
              child_id: row.id,
              enterprise_defalut: row.enterprise_defalut
            };
          })
        );

        return results;
      }, []);
    },
    //格式化时间控件
    handleDateChange () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.update_time_start =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.update_time_end =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.update_time_start =
            this.order_time_range[0].getTime() / 1000;
          this.params.update_time_end =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.update_time_start = "";
        this.params.update_time_end = "";
      }
    },
    /** 打开编辑弹窗 */
    async handleGoodsAudit (row) {
      this.examinationDialog.config.title = row ? "编辑操作" : "添加操作";
      this.goodsAuditForm = {
        company_name: '',
        enterprise_collecting_account_detail_dtolist: [//开户银行
          {
            bank_name: '',
            bank_account: ''
          }
        ]
      }
      if (row) {
        //调用详情接口
        const response = await API_settingCustomer.getCustomerAccount(row.id)
        this.goodsAuditForm = { ...response }
        this.isCompanyName = response.company_name;
      }
      this.examinationDialog.display();
    },
    /**新增开户银行 */
    addBank () {
      this.goodsAuditForm.enterprise_collecting_account_detail_dtolist.push({
        bank_name: '',
        bank_account: ''
      })
    },
    /** 删除开户银行 */
    delBank (index) {
      if (this.goodsAuditForm.enterprise_collecting_account_detail_dtolist.length > 1) this.goodsAuditForm.enterprise_collecting_account_detail_dtolist.splice(index, 1);
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_GoodsAuditList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_GoodsAuditList();
    },
    /**提交充值表单 */
    submitGoodsAuditForm () {
      let { promise, resolve, reject } = Promise.withResolvers();
      this.$refs.goodsAuditForm.validate((valid) => {
        if (valid) {
          let goodsAuditForm = {
            ...this.goodsAuditForm,
          }
          if (goodsAuditForm.id) {
            API_settingCustomer.editCustomerAccount(goodsAuditForm).then((res) => {
              this.$message.success('保存成功');
              this.GET_GoodsAuditList();
              resolve(true)
            });
          } else {
            API_settingCustomer.addCustomerAccount(goodsAuditForm).then((res) => {
              this.$message.success('保存成功');
              this.GET_GoodsAuditList();
              resolve(true)
            });
          }
        } else {
          reject(false)
        }
      });
      return promise
    },
    /** 搜索事件触发 */
    searchEvent (data) {
      this.params.page_no = 1;
      this.GET_GoodsAuditList();
    },
    /** 获取列表数据 */
    GET_GoodsAuditList () {
      this.loading = true;
      let params = {
        ...this.params
      };
      params[this.key_word] = params.keyWord
      delete params.keyWord
      API_settingCustomer.getEcaList(params)
        .then(response => {
          this.loading = false;
          this.tableData = response;
          this.tableData.data = this.mergeSkuList(response.data)
          console.log(this.tableData.data);
        })
        .catch(() => (this.loading = false));
    },
    /**跳转进项明细页面 */
    openDetail (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        path: `/finance/financialAccountManagement/collectionAccountManagement/income/${row.id}`
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.bank-list {
  border: 2px dashed #eee;
  padding: 20px 10px 5px;
  margin-top: 10px;
  position: relative;

  .delBank {
    position: absolute;
    right: -14px;
    top: -14px;
    cursor: pointer;
    font-size: 30px;
  }
}

.examinationDialog {
  /deep/ .el-scrollbar__bar.is-vertical {
    width: 0;
  }
}
</style>